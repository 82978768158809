<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data"></n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('create', 'whole-sale')"
              >
                {{ $t('button.saveChanges') }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t('button.back') }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInput from '@/components/NInput'
import FormInput from './formInput'

const WholeSaleRepository = Repository.get('wholeSale')

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        username: '',
        phoneNumber: '',
        password: '',
        telegramId: '',
        isEnable: true,
        isReadonly: false,
      },
      loading: false,
    }
  },
  methods: {
    submit() {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.loading = true
          WholeSaleRepository.create(this.data)
            .then((response) => {
              this.$router.push({
                name: 'view-whole-sale',
                params: { id: response.data.data.id },
              })
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(error.response?.data?.message)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      if (value.key === 'password' || value.key === 'retypePassword') {
        value.rules = value.rules.replace('required|', '')
        value.rules = value.rules.replace('required', '')
        value.rules = value.rules.replace('', 'required|')
      }

      return value
    })

    console.log(fields, 'fields')

    return { fields }
  },
}
</script>
